<template>
  <div
    class="flex overflow-hidden"
    :class="{
      'rounded-[12px]': !noRounded,
      'card-image-overlay': !noRounded && !noOverlay,
    }"
  >
    <div
      v-if="!disableLabel"
      class="absolute left-0 top-[16px] z-20 rounded-r-[4px] py-[1px] pl-[12px] pr-[4px] text-[12px] font-bold uppercase leading-[15px] text-white-100 xl:text-[14px] xl:leading-[17px]"
      :class="getDataByModel?.labelBgColor"
    >
      <slot name="badge">
        {{ data?.card_label || getDataByModel?.label }}
      </slot>
    </div>

    <div
      class="aspect-wrap relative w-full"
      :style="`--local-aspect-w: ${aspect.w}; --local-aspect-h: ${aspect.h}; padding-bottom: calc(var(--local-aspect-h) / var(--local-aspect-w) * 100%);`"
    >
      <img
        v-lazy-load
        :src="getSrc"
        :alt="data?.image?.alt || data?.title"
        class="w-full object-cover transition-all duration-300 group-hover:scale-125"
        :class="position"
      />
    </div>
    <!-- <slot name="cover"></slot> -->
    <slot></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
    require: true,
  },
  disableLabel: { type: Boolean, default: false },
  priorityImage: { type: String, default: '' },
  position: { type: String, default: 'object-center' },
  noRounded: { type: Boolean, default: false },
  noOverlay: { type: Boolean, default: false },
  aspect: {
    type: Object,
    default: () => ({
      w: 16,
      h: 9,
    }),
  },
});

const { isDesktop, isPad, isMobile } = useBreakpoint();
const getCardSettings = useCardSettings();

const getDataByModel = computed(() => {
  return getCardSettings(props.data);
});

const getSrc = computed(() => {
  const src =
    props.priorityImage ||
    (isDesktop.value && props.data?.image?.desktop) ||
    (isPad.value && props.data?.image?.desktop) ||
    (isMobile.value && props.data?.image?.mobile) ||
    props.data?.image?.src ||
    props.data?.image?.preview ||
    props.data?.test_data?.images[0]?.src;
  return src;
});

// import getCardSettings from '@/mixins/getCardSettings';
// import getScreenBreakpoint from '@/mixins/getScreenBreakpoint';

// export default {
//   mixins: [getCardSettings, getScreenBreakpoint],

//   props: {
//     data: {
//       type: Object,
//       default: () => ({}),
//       require: true,
//     },
//     disableLabel: { type: Boolean, default: false },
//     priorityImage: { type: String, default: '' },
//     position: { type: String, default: 'object-center' },
//     noRounded: { type: Boolean, default: false },
//     aspect: {
//       type: Object,
//       default: () => ({
//         w: 16,
//         h: 9,
//       }),
//     },
//   },

//   computed: {
//     getDataByModel() {
//       return this.getCardSettings(this.data);
//     },

//     getSrc() {
//       return (
//         this.priorityImage ||
//         (this.isDesktop && this.data?.image?.desktop) ||
//         (this.isPad && this.data?.image?.desktop) ||
//         (this.isMobile && this.data?.image?.mobile) ||
//         this.data?.image?.src ||
//         this.data?.image?.preview ||
//         this.data?.test_data?.images[0]?.src
//       );
//     },
//   },
// };
</script>

<style lang="scss" scoped>
.card-image-overlay {
  position: relative;
  &::before {
    content: '';
    @apply absolute left-0 right-0 top-0 bottom-0 rounded-[12px] z-10;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
}
</style>
